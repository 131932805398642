import React from 'react';
import './FAQ.css'; // Import your CSS file


function FAQ() {
  return (
  
    <div className="container">
      <h1>Frequently Asked Questions</h1>
      <h3>When/where is the wedding?</h3>
      <p>Our wedding will take place at Sarma, a restaurant in Somerville, MA (249 Pearl St, Somerville, MA 02145) on September 13th, 2025. The festivities will start promptly (well, promptly-ish) at 5 PM, and go until about 10:30 PM. There will be an afterparty for anyone who still wants to boogie at the Abbey, a bar in Cambridge.</p>
      <h3>What should I wear?</h3>
      <p>This is a FANCY wedding!! Please use it as an excuse to get dressed to the nines, but you can skip the tuxedo, a suit is fine. For folks in dresses, we would love if you wore tea (ankle) or full length dresses in <strong> colorful floral prints</strong>. Ryan would like it to be known that the wedding is definitely <em>not</em> Jimmy Buffet themed, but Brenna would like it to be known that it is definitely <em>is</em> Jimmy Buffet themed. Upscale Jimmy Buffet. Jimmy Buffet if you forced him to go to the Oscars.</p>
   
    </div>
  );
}

export default FAQ;